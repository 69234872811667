export default {  
    isWechat:function(){  
        var ua = window.navigator.userAgent.toLowerCase();  
        if(ua.match(/micromessenger/i) == 'micromessenger'){  
            return true;  
        }else{  
            return false;  
        }  
    },  
    jsApiCall(data ,callback_succ_func ,callback_error_func){  
        //使用原生的，避免初始化appid问题  
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            // wx3774b2c673be9d6a
            // data['appid']
            'appId': data['appid'],
            'timeStamp': data['timestamp'],  
            'nonceStr': data['nonceStr'], // 支付签名随机串，不长于 32 位  
            'package': data['packages'], // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）  
            'signType': data['signType'] || "SHA1", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'  
            'paySign': data['sign'], // 支付签名  
        },  
        function(res) {
            //     if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // // 使用以上方式判断前端返回,微信团队郑重提示：
            //     callback_succ_func(res);  
            //       //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // }  else {
            //     callback_error_func(res); 
            // }
            var msg = res.err_msg ?res.err_msg :res.errMsg;  
            //WeixinJSBridge.log(msg);  
            switch (msg) {  
                case 'get_brand_wcpay_request:ok': //支付成功时  
                    if(callback_succ_func){  
                        callback_succ_func(res);  
                    }  
                    break;  
                default: //支付失败时  
                    WeixinJSBridge.log('支付失败!'+msg+',请返回重试.');  
                    if(callback_error_func){  
                        callback_error_func({msg:msg});  
                    }  
                    break;  
            }  
        })
    },
    payment:function(data ,callback_succ_func ,callback_error_func){  
        if(!this.isWechat()){  
            return ;  
        }  
        if (typeof WeixinJSBridge == "undefined") {  
            if (document.addEventListener) {  
                document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);  
            } else if (document.attachEvent) {  
                document.attachEvent('WeixinJSBridgeReady', this.jsApiCall);  
                document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);  
            }  
        } else {  
            this.jsApiCall(data ,callback_succ_func ,callback_error_func);  
        }  
    }  
}
