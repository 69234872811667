<template>
  <div class="bookinfo">
    <van-swipe class="my-swipe book_img" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in fengmian" :key="index"> <img v-lazy="item" /></van-swipe-item>
    </van-swipe>
    <!-- 售价情况 -->
    <div class="subject-Info">
        <div class="title">
            <span class="left-title ellipse">{{info.title}}</span>
            <span class="right-price"> <span class="fuhao">￥</span>{{info.price}}</span>
        </div>
        <div class="infomation">
            <div class="tipsbox">
                <span class="jiangshi">讲师：{{info.mentor_name}}</span>
                <span class="adress">活动地址：{{info.address}}</span>
            </div>
            <div class="describe">
                {{info.description}}
            </div>
        </div>
        <div class="subject-time">
            <div class="time-info">
                <p>
                    <img src="@assets/img/time.png">
                    <span>活动时间：{{info.start_time}}</span>
                </p>
                <p>
                    <img src="@assets/img/Jiezhi.png">
                    <span>报名截止：{{info.end_time}}</span>
                </p>
            </div>
            <div @click="bannerBox = true" class="code-img">
                <img :src="code">
            </div>
        </div>
    </div>
    <!-- 灰色隔离带 -->
    <div class="gang-box" />
    <!-- 列表 -->
    <div class="list-box">
        <ul class="list-content">
            <li class="first-li"><span>头像</span><span>昵称</span><span>手机号</span><span>下单时间</span></li>
            <li class="other-li" v-for="(value, index) in userList" :key="index">
                <span class="first-span"><img :src="value.avatar"></span>
                <span class="ellipse1">{{value.real_name}}</span>
                <span>{{value.mobile}}</span>
                <span>{{value.pay_time}}</span>
            </li>
        </ul>
        <div class="btn" @click="goAll">
            全部
        </div>
    </div>
    <!-- 点击报名 -->
    <div class="baominBtn" @click="showPayBox">
        {{info.status == 3 ? '活动已结束' : info.status == 1 ? '活动未开始' : '立即报名'}}
    </div>
    <!-- 图文详情 -->
    <div class="imgArticle">
        <img v-for="(value, index) in xiangqing" :key="index" class="info-omg" v-lazy="value">
        <!-- <div>
            <ul>
                <li v-for="(item, index) in 8" :key="index"><img :src="imgInfo"></li>
            </ul>
        </div> -->
    </div>
    <!-- 报名弹出层 -->
    <van-popup v-model="Payshow" closeable round position="bottom">
        <div class="book_info">
            <div class="top">
                <img v-lazy="fengmian[0]" />
                <div>
                    <p class="ellipse">{{info.title}}</p>
                    <p><span class="baomin-fuhao">￥</span>{{info.price}}</p>
                </div>
            </div>
        </div>
        <div class="user-info">
            <van-field v-model="text" placeholder="请输入您的名字" label="姓名 :" />
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="tel" type="tel" placeholder="请输入手机号码，方便联系" label="手机号 :" />
        </div>
        <div class="payBox">
            <van-button @click="setUser" v-preventReClick>立即报名</van-button>
        </div>
    </van-popup>
    <!-- 海报对话框closeOnClickOverlay -->
    <van-dialog v-model="bannerBox" closeOnClickOverlay :show-confirm-button="false">
        <img class="imgquyu" v-lazy="fengmian[0]" />
        <div class="banner-subject-Info">
            <div class="banner-title">
                <span class="banner-left-title ellipse">{{info.title}}</span>
                <span class="banner-right-price"><span class="banner-fuhao">￥</span>{{info.price}}</span>
            </div>
            <div class="banner-infomation">
                <div class="banner-tipsbox">
                    <span class="banner-jiangshi">讲师：{{info.mentor_name}}</span>
                    <span class="banner-adress">活动地址：{{info.address}}</span>
                </div>
            </div>
            <div class="banner-subject-time">
                <div class="banner-time-info">
                    <p>
                        活动时间：{{info.start_time}}
                    </p>
                    <p>
                        报名截止：{{info.end_time}}
                    </p>
                </div>
                <div @click="bannerBox = true" class="banner-code-img">
                    <qriously :value="info.link" :size="67" />
                </div>
            </div>
        </div>
        <img class="qixiaoBtn" @click="bannerBox = false" :src="quxiao">
    </van-dialog>
  </div>
</template>

<script>
import payment from '@plugins/wxpay'
import { getWxOpenId } from '@plugins/request'
import { getlist, setNewUser, activityInfo, callback_paid } from '@plugins/server'

export default {
  name: 'Activity',
  data() {
      return {
        info: {},
        fengmian: [],
        xiangqing: [],
        Payshow: false,
        bannerBox: false,
        userList: [],
        bgPng: require('@assets/img/zwt.jpg'),
        url: encodeURIComponent(window.location.href),
        code: require('@assets/img/code.png'),
        quxiao: require('@assets/img/quxaio.png'),
        imgInfo: require('@assets/img/info.jpg'),
        text: '',
        tel: '',
        opendIdInfo: {},
        payComplete: {}
      }
  },
  async mounted () {
        if (this.GetQueryString(window.location.search, 'code')) {
            let {data, state, message} = await getWxOpenId({code : this.GetQueryString(window.location.search, 'code')})
            if(state == 1) {
                this.opendIdInfo = data
                sessionStorage.setItem('wxInfo', JSON.stringify(data))
                sessionStorage.setItem('Account', 666)
            } else {
                this.$toast(message)
            }
        }
        let flag = sessionStorage.getItem('Account')
        if(!flag) {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa6420f1cd141be8f&redirect_uri=' + this.url + '&response_type=code&scope=snsapi_userinfo&state=666shuyouhui#wechat_redirect'
        }
        activityInfo({mentorId: this.$route.query.id ? this.$route.query.id : 10}).then(res => {
            if(res.state == 1) {
                this.info = res.data
                document.title = res.data.title
                this.fengmian = res.data.cover.split(",")
                this.xiangqing = res.data.detail.split(",")
                // 分享
                // 1 https://enroll.666syh.com
                // 2 https://enroll.666syh.com/dys
                this.$wxShare(
                    {
                        title: this.info.title, // 分享标题
                        desc: this.info.description, // 分享描述
                        link: 'https://enroll.666syh.com/other', // 分享链接
                        imgUrl: this.fengmian[0] // 分享图标
                    },
                    () => {}
                );
                this.getList(res.data.activity_id)
            } else {
                this.$toast(res.message)
            }
        })
  },
  methods: {
        getList(data) {
            getlist({page_size: 6, page_no: 1, activity_id: data}).then(res => {
            if(res.state == 1) {
                this.userList = res.data
            } else {
                this.$toast(res.message)
            }
            })
        },
        goAll() {
            this.$router.push({path: '/All', query: {
                activity_id: this.info.activity_id
            }})
        },
        successBack(obj) {
            this.$toast.loading({
                message: '报名中...',
                forbidClick: true,
                duration: 0,
            })
            callback_paid({
                payChannel: 'WXPAY_JSAPI',
                orderType: 'learnEnroll',
                orderSn: this.payComplete.orderSn
            })
            setTimeout(() => {
                this.$toast.clear()
                this.$toast('报名成功')
                this.getList()
                this.Payshow = false
            }, 2000);
        },
        errorBack(obj) {
            this.$toast.fail('取消报名!')
            // this.$toast.fail(obj.msg)
        },
        // 获取url参数
        GetQueryString(url, name){
            var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)")
            var r = url.substr(1).match(reg)
            if(r!=null) return unescape(r[2])
            return null;
        },
        // 立即报名
        setUser() {
            let wxInfo = JSON.parse(sessionStorage.getItem('wxInfo'))
            if(this.text && this.tel) {
                setNewUser({
                    mobile: this.tel,
                    nick_name: this.text,
                    open_id: this.opendIdInfo.open_id || wxInfo.open_id,
                    avatar: this.opendIdInfo.avatar || wxInfo.avatar,
                    pay_amount: this.info.price ? this.info.price : 0,
                    mentorId: this.$route.query.id ? this.$route.query.id : 10
                }).then(res => {
                    if(res.state == 1) {
                        this.payComplete = res.data
                        payment.payment(res.data, this.successBack, this.errorBack)
                    } else {
                        this.$toast(res.message)
                    }
                })
            } else {
                this.$toast('请输入姓名与手机号')
            }
        },
        // 防止没有拉到授权重新请求
        showPayBox() {
            if(this.info.status == 3) return this.$toast('活动已结束!')
            if(this.info.status == 1) return this.$toast('活动未开始!')
            let wxInfo = JSON.parse(sessionStorage.getItem('wxInfo'))
            if(this.opendIdInfo.open_id || wxInfo.open_id) {
                this.Payshow = true
            } else {
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa6420f1cd141be8f&redirect_uri=' + this.url + '&response_type=code&scope=snsapi_userinfo&state=666shuyouhui#wechat_redirect'
            }
        }
    }

}
</script>
<style lang="scss" scoped>
  .bookinfo {
    background-color: #fff;
    .book_img {
        height: 375px;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .subject-Info {
        height: 245px;
        .title {
            padding: 15px;
            height: 76px;
            display: flex;
            justify-content: space-between;
            font-family: PingFang SC;
            align-items: center;
            .left-title {
                font-size: 17px;
                font-weight: bold;
                color: #202020;
                line-height: 21px;
                width: 65%;
                text-align: left;
            }
            .right-price { 
                width: 30%;
                font-size: 20px;
                font-weight: bold;
                color: #FF6464;
                line-height: 21px;
                .fuhao {
                    font-size: 17px;
                }
            }
        }
        .infomation {
            height: 90px;
            .tipsbox {
                font-size: 13px;
                font-weight: bold;
                color: #AA7F4F;
                line-height: 21px;
                text-align: left;
                padding-left: 15px;
                // display: flex;
                // justify-content: space-between;
                .jiangshi {
                    height: 25px;
                    background: #FFF6E3;
                    border-radius: 4px;
                    padding: 7px;
                    margin-right: 10px;
                }
                .adress {
                    height: 25px;
                    background: #FFF6E3;
                    border-radius: 4px;
                    padding: 7px;
                }
            }
            .describe {
                text-align: left;
                padding: 12px 35px 12px 15px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #969696;
            }
        }
        .subject-time {
            display: flex;
            justify-content: space-between;
            padding-left: 15px;
            align-items: center;
            .time-info {
                display: flex;
                flex-direction: column;
                p {
                    padding: 0 10px;
                    height: 24px;
                    background: #F7F7F7;
                    border-radius: 4px;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #545454;
                    // line-height: 24px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 13px;
                        height: 14px;
                        margin-right: 8px;
                    }
                }

            }
            .code-img {
                margin-right: 30px;
                img {
                    width: 41px;
                    height: 41px;
                }
            }
        }
    }
    .gang-box {
        height: 12px;
        background-color: #f8f8f8;
    }
    .list-box {
        background-color: #fff;
        padding: 0 15px 30px;
        .list-content {
            .first-li {
                height: 55px;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #545454;
                line-height: 55px;
                span {
                    text-align: left;
                    &:nth-of-type(2) {
                        // margin-right: 50px;
                        padding-right: 5px;
                    }
                    &:nth-of-type(3) {
                        margin-right: 35px;
                        margin-left: 25px;
                    }
                    &:nth-of-type(4) {
                        padding-right: 15px;
                    }
                }
            }
            .other-li {
                height: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #545454;
                span {
                    text-align: left;
                    &:nth-of-type(2) {
                        width: 50px;
                    }
                }
                .first-span {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
        .btn {
            margin-top: 10px;
            height: 35px;
            background: #EEEEEE;
            border-radius: 4px;
            line-height: 35px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #545454;
        }
    }
    .imgArticle {
        // margin-top: 10px;
        padding-bottom: 50px;
        .info-omg {
            width: 100%;
            vertical-align: middle;
        }
    }
    .baominBtn {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background: #EED9B0;
        border: 1px solid #EAEAEA;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #202020;
    }
    .van-popup {
        background-color: #f4f4f4;
        .book_info {
            padding: 18px 70px 10px 15px;
            background-color: #fff;
            .top {
                display: flex;
                justify-content: space-around;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 7px;
                    margin-right: 13px;
                }
                div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        &:nth-of-type(1) {
                            color: #333333;
                            font-size: 15px;
                            margin-bottom: 18px;
                            text-align: left;
                        }
                        &:nth-of-type(2) {
                            font-size: 17px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #FF6464;
                            text-align: left;
                            .baomin-fuhao {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .user-info {
            margin-top: 10px;
        }
        .payBox {
            width: 100%;
            height: 65px;
            padding: 10px 15px;
            background-color: #ffffff;
            margin-top: 10px;
            .van-button {
                width: 100%;
                height: 100%;
                background-color: #eed9b0;
                color: #202020;
                border-radius: 8px;
            }
        }

    }
    .van-dialog {
        overflow: unset;
        width: 321px;
        border-radius: 20px;
        .imgquyu {
            width: 100%;
            height: 321px;
            vertical-align: middle;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        .banner-subject-Info {
            height: 200px;
            .banner-title {
                padding: 11px 15px 12px;
                height: 70px;
                display: flex;
                justify-content: space-between;
                font-family: PingFang SC;
                align-items: center;
                .banner-left-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #202020;
                    line-height: 21px;
                    width: 65%;
                    text-align: left;
                }
                .banner-right-price {
                    width: 20%;
                    font-size: 18px;
                    font-weight: bold;
                    color: #FF6464;
                    line-height: 21px;
                    .banner-fuhao {
                        font-size: 14px;
                    }
                }
            }
            .banner-infomation {
                height: 36px;
                .banner-tipsbox {
                    font-size: 12px;
                    font-weight: bold;
                    color: #AA7F4F;
                    line-height: 21px;
                    text-align: left;
                    padding-left: 15px;
                    .banner-jiangshi {
                        height: 24px;
                        background: #FFF6E3;
                        border-radius: 4px;
                        padding: 7px;
                        margin-right: 10px;
                    }
                    .banner-adress {
                        height: 24px;
                        background: #FFF6E3;
                        border-radius: 4px;
                        padding: 7px;
                    }
                }
            }
            .banner-subject-time {
                display: flex;
                justify-content: space-between;
                // padding-left: 15px;
                padding: 0 15px;
                align-items: center;
                .banner-time-info {
                    display: flex;
                    flex-direction: column;
                    p {
                        padding: 0 10px;
                        height: 24px;
                        background: #F7F7F7;
                        border-radius: 4px;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }

                }
                .banner-code-img {
                    padding-bottom: 5px;
                    img {
                        width: 67px;
                        height: 67px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .qixiaoBtn {
        position: absolute;
        bottom: -53px;
        left: 50%;
        transform: translateX(-50%);
        width: 33px;
        height: 33px;
        z-index: 999;
    }
  }
</style>
