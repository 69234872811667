import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import 'amfe-flexible/index.js'
import wxShare from './plugins/wxconfig'
import VueQriously from 'vue-qriously'
import preventReClick  from './plugins/click_back'

Vue.use(VueQriously)
Vue.use(preventReClick)


import './assets/style/normal.css'
import { Lazyload } from 'vant';
Vue.config.productionTip = false
Vue.prototype.$wxShare = wxShare

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
