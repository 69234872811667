import { getWXconfig } from './request'
import wx from "weixin-js-sdk"; // 注意版本号，部分接口可能会删掉 官网 https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
/**
 * 页面调用微信分享方法
 *
 * @param  {Object} obj      分享的标题，描述，图片等
 * @param  {type} callback
 * @return {type}
 */
function wxShare(obj, callback) {
  // const href = location.href.split('?')[0]
  //base64当前页面地址传给后端生成签名
  getWXconfig({ url: 'https://enroll.666syh.com/' }).then(res => {
    if (res.state != 1) {
      return false
    }
    const data = res.data
    wx.config({
      debug: false, // 开启调试模式,
      appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名，见附录1
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ] // 必填，需要使用的JS接口列表
    });
    shareConfig(obj, callback);
  })
}

function shareConfig(obj, callback) {
  console.log(obj, '微信微信')
  wx.ready(() => {
    //分享到朋友圈
    wx.updateTimelineShareData({
      title: obj.title, // 分享标题
      link: obj.link, // 分享链接
      imgUrl: obj.imgUrl ? obj.imgUrl : "", // 分享图标
      success: () => {
        typeof callback === "function" && callback();
      },
      cancel: () => {
        // 用户取消分享后执行的回调函数
      }
    });

    // 分享给微信好友
    wx.updateAppMessageShareData({
      title: obj.title, // 分享标题
      desc: obj.desc, // 分享描述
      link: obj.link, // 分享链接
      imgUrl: obj.imgUrl ? obj.imgUrl : "",
      success: () => {
        typeof callback === "function" && callback();
      },
      cancel: () => {
        // 用户取消分享后执行的回调函数
      }
    });
  })
  wx.error(function(res){
  });

}

export default wxShare;