import axios from 'axios'
import { Toast } from 'vant'

let baseURL = 'https://app.666syh.com/book_api';

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // let token = localStorage.getItem('mytoken')
    // if (token) {
    //   config.headers['token'] = token
    // } else {
    //   config.headers['token'] = ''
    // }
    return config
  },
  error => {
    console.log(error) // for debug 11
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: Toast.fail('请求错误'); break
        case 500: Toast.fail('服务器内部错误'); break
        default: break
      }
    }
    return Promise.reject(error)
  }
)

  // 获取oppid /user/gzh/weixin/grantAuthorization
function getWxOpenId (data) {
    return service({
      url: '/user/gzh/weixin/grantAuthorization',
      method: 'post',
      data
    })
  }

  // wxjssdk配置 /weixin/share/getConfig
  function getWXconfig (data) {
    return service({
      url: '/weixin/share/getConfig',
      method: 'post',
      data
    })
  }

  export {
    getWxOpenId,
    getWXconfig
  }

