import axios from 'axios'
import { Toast } from 'vant'

let baseURL = 'https://app.666syh.com/pay_api';
// 192.168.124.140
// let baseURL = 'https://192.168.124.140:8710';

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // let token = localStorage.getItem('mytoken')
    // if (token) {
    //   config.headers['token'] = token
    // } else {
    //   config.headers['token'] = ''
    // }
    return config
  },
  error => {
    console.log(error) // for debug 11
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: Toast.fail('请求错误'); break
        case 500: Toast.fail('服务器内部错误'); break
        default: break
      }
    }
    return Promise.reject(error)
  }
)

// 活动详情 /learnEnroll/activity
function activityInfo (data) {
  return service({
    url: '/learnEnroll/activity',
    method: 'post',
    data
  })
}

  // 报名列表 /user/gzh/weixin/grantAuthorization
function getlist (data) {
    return service({
      url: '/learnEnroll/enrollList',
      method: 'post',
      data
    })
  }

  function callback_paid (data) {
    return service({
      url: '/pay/order/paid',
      method: 'post',
      data
    })
  }
  // 报名 /learnEnroll/enroll
  function setNewUser (data) {
    return service({
      url: '/learnEnroll/enroll',
      method: 'post',
      data
    })
  }

  export {
    getlist,
    setNewUser,
    activityInfo,
    callback_paid
  }

